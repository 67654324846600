@import '~assets/styles/base/typography';
@import '~assets/styles/base/mixins';
/*.woff - Modern Browsers */
/*.woff2 - Next-gen Modern Browsers */
/*.tff - Safari, Android, iOS */

@font-face {
  font-family: 'llisarniq';
  src: local('llisarniq'),
    url('./assets/fonts/Ilisarniq-Regular.ttf') format('truetype'),
    url('./assets/fonts/Ilisarniq-Regular.woff') format('woff'),
    url('./assets/fonts/Ilisarniq-Regular.woff2') format('woff2');
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#root {
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 96px- 96px);
  position: relative;
}
html {
  box-sizing: border-box;
  // this defines what 1rem is equivalent to 1rem==10px
  font-size: 62.5%;
}

body {
  font-family: $font-family;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

button {
  background-color: unset;
  border-style: unset;
}

.side-bar {
  overflow-y: auto;
  position: absolute;
  height: calc(100% - 15rem);
  top: 7.5rem;
  left: 0;
  padding-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4rem);
  color: $light-color;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  z-index: 1;
  width: 25vw;
  transform: translateX(-100%);

  &--show {
    transform: translateX(0);
    @include respond(phone) {
      width: 300px;
    }
  }
}
