@keyframes dropDown {
  from {
    transform: translateY(-50rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes darken-background {
  from {
    background-color: rgba(0,0,0, 0.1);
  }
  to {
    background-color: rgba(0,0,0, 0.7);
  }
}