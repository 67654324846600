@import './variables';

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: $default-font-size;
  line-height: 1.7;
  color: #363a3d;
  padding: 3rem;
}

.paragraph {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  line-height: 26px;
}

.paragraph--small {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  line-height: 20px;
}
