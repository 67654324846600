@mixin btn-solid($color) {
  background: $color;
  border: none;
  transition: all 0.3s ease;
  color: $white-color;

  &:hover,
  &:focus {
    background: darken($color, 8%);
  }
}

@mixin btn-transparent($color) {
  background: transparent;
  border: solid 2px $color;
  color: $color;
  letter-spacing: .1rem;

  &:hover,
  &:focus {
    background: $color;
    color: white;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

@mixin btn-small() {
  min-width: 4rem;
}

@mixin btn-regular() {
  min-width: 8rem;
  
}

@mixin btn-large() {
  min-width: 10rem;
}


/*
0 - 600px:      Phone
600 - 900px:    Tablet potrait
900 - 1200px:   Tablet landscape
1200 - 1800     where default style apply
1800px + :      Big Desktop
*/

//1em = 16px;

@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media (max-width: 37.5em) {
      @content
    }
  }

  @if $breakpoint==tab-port {
    @media (max-width: 56.25em) {
      @content
    }
  }

  @if $breakpoint==tab-land {
    @media (max-width: 75em) {
      @content
    }
  }

  @if $breakpoint==big-desktop {
    @media (min-width: 112em) {
      @content
    }
  }

}