$font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

/* colors */
$background-black: black;
$background-white: #f6f6f6;
$background-light-gray: #dbdbdb;
$background-dark-gray: #616161;
$background-gray: #979797;
$test-background: #f5f6fa;
$secondary-background: #DFE2EE;

$text-color: rgb(26, 43, 73);
$iron-color: #dcdde1;
$mercury-color: #e6e6e6;
$concrete-color: #f2f2f2;
$primary-color: #ffd15c;
$dark-yellow: rgb(255, 187, 14);
$dark-color: #1c1e20;
$secondary-color: #010b15;
$blue-color: #41cee2;
$light-color: #e4e4e4;
$disable-color: #515151;
$white-color: #ffffff;
$success-color: #008000;
$warning-color: #fda50f;
$error-color: #ef233c;
$grey-color: #696A6C;


$xs: 6rem;
$sm: 14rem;
$md: 16rem;
$lg: 20rem;

$default-font-size: 1.6rem;
