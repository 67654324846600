@import '~assets/styles/base/variables';

.side-bar {
  &__topic {
    margin: 3rem 2rem 2rem 2rem;
    border-bottom: 1px solid $light-color;
    overflow: hidden;
  }

  .expanding {
    padding-bottom: 2rem;
    .progressbar {
      height: auto;
    }
  }

  &__heading {
    margin-bottom: 2rem;
    letter-spacing: 0.1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  }

  &__arrow {
    height: 2rem;
    width: 2rem;
    color: white;
    cursor: pointer;
  }

  &__topic &__arrow {
    transform: rotateX(180deg);
  }

  .expanding &__arrow {
    transform: rotateX(0);
  }

  &__topic .progressbar {
    height: 0;
  }

  &__legends {
    padding-top: 3rem;
  }

  &__exitIcon {
    cursor: pointer;
    text-align: right;
    margin: 1rem;
    margin-right: 2rem;
  }

  .legend {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }

    &--unanswered::before {
      background-color: $grey-color;
    }

    &--answered::before {
      background-color: $primary-color;
    }

    &--bookmarked::before {
      background-color: $blue-color;
    }
  }

  &__warning {
    padding: 2rem;
    font-size: 1.4rem;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50rem;
  }
}
